/** @format */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import MyComponent from "../Map/Map";
import { getExternalCompanies } from "../../../actions/companyAction";
import {
  // addClient,
  addPassengerFormAction,
  // addStudentAction,
} from "../../../actions/userActions";
// import AdminSettingPaymentAddCompanyModal from "../../../components/adminComponents/adminSettingComponents/AdminSettingPaymentAddCompanyModal";
import { useNavigate } from "react-router-dom";
import Select, { NonceProvider } from "react-select";
import AdminSettingAddFacilityModal from "../adminSettingComponents/AdminSettingAddFacilityModal";
import AdminSettingPaymentAddCompanyModal from "../adminSettingComponents/AdminSettingPaymentAddCompanyModal";

const AdminPassengerAddComponent = ({
  showDropDown,
  openDropDown,
  setSelectedStudent,
}) => {
  let [showModal, setShowModal] = useState();
  // let [selectedCompany, setSelectedCompany] = useState();
  const externalCompanies = useSelector((state) => state.externalCompanies);
  const auth = useSelector((state) => state.auth);
  const students = useSelector((state) => state.students);
  const { loading } = students;
  const kpi = useSelector((state) => state.kpi);
  const { facility } = kpi;
  useEffect(() => {
    dispatch(getExternalCompanies({ companyId: auth.user.companyId.id }));
  }, [auth]);

  let [selectedCompanyId, setSelectedCompanyId] = useState("");
  const [values, setValues] = useState({
    // companyId: "63cb14f53f55723694dbd185",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    isRouteUser: false,
    // workPhone: '',
    // otherPhone: '',
    // clientWorkInformation: '',
    address: "",
    // state: '',
    city: "",
    zip: "",
    type: "",
    notes: "",
    externalCompanyId: "",
    facility: "",
  });

  const dispatch = useDispatch();

  const studentData = {
    companyId: auth.user.companyId.id,
    firstName: values.firstName,
    lastName: values.lastName,
    email: values.email,
    phone: values.phone,
    notes: values.notes,
    isRouteUser: false,
    address: values.address,
    // state: values.state,
    city: values.city,
    zip: values.zip,
    facility: values.facility,
    isCompany: values.type === "Company" ? true : false,
    externalCompanyId: values.type === "Company" ? selectedCompanyId : "",
  };

  // const submitHandler = (e) => {
  //   e.preventDefault();
  //   dispatch(addStudentAction(studentData, successHandler));
  // };
  const successHandler = (studentId) => {
    setSelectedStudent((prev) => ({ ...prev, studentId }));
    openDropDown();
  };

  const handleChange = (fieldName) => (event) => {
    setValues({
      ...values,
      [fieldName]: event.target.value,
    });
  };

  // const handlePhone = (fieldName) => (event) => {
  //   if (event.target.value.length === 1) {
  //     if (event.target.value !== "(") {
  //       setValues({
  //         ...values,
  //         [fieldName]: "(" + event.target.value,
  //       });
  //     }
  //   } else if (event.target.value.length === 4) {
  //     if (event.target.value !== ")") {
  //       setValues({
  //         ...values,
  //         [fieldName]: event.target.value + ")-",
  //       });
  //     }
  //   } else if (event.target.value.length === 9) {
  //     if (event.target.value !== "-") {
  //       setValues({
  //         ...values,
  //         [fieldName]: event.target.value + "-",
  //       });
  //     }
  //   } else {
  //     setValues({
  //       ...values,
  //       [fieldName]: event.target.value,
  //     });
  //   }
  // };
  // const alphabets = [
  //   "a",
  //   "b",
  //   "c",
  //   "d",
  //   "f",
  //   "g",
  //   "h",
  //   "i",
  //   "j",
  //   "k",
  //   "l",
  //   "m",
  //   "n",
  //   "o",
  //   "p",
  //   "q",
  //   "r",
  //   "s",
  //   "t",
  //   "u",
  //   "v",
  //   "w",
  //   "x",
  //   "y",
  //   "z",
  //   "A",
  //   "B",
  //   "C",
  //   "D",
  //   "F",
  //   "G",
  //   "H",
  //   "I",
  //   "J",
  //   "K",
  //   "L",
  //   "M",
  //   "N",
  //   "O",
  //   "P",
  //   "Q",
  //   "R",
  //   "S",
  //   "T",
  //   "U",
  //   "V",
  //   "W",
  //   "X",
  //   "Y",
  //   "Z",
  //   "+",
  //   "-",
  //   "/",
  //   "*",
  //   "!",
  //   "@",
  //   "#",
  //   "$",
  //   "%",
  //   "^",
  //   "&",
  //   "_",
  //   "(",
  //   ")",
  //   ",",
  //   ".",
  // ];

  const handlePhone = (fieldName) => (event) => {
    const numericValue = event.target.value.replace(/\D/g, "");
    let formattedValue = "";
    if (numericValue.length > 0) {
      formattedValue = `(${numericValue.slice(0, 3)}`;
    }
    if (numericValue.length > 3) {
      formattedValue += `)${numericValue.slice(3, 6)}`;
    }
    if (numericValue.length > 6) {
      formattedValue += `-${numericValue.slice(6, 10)}`;
    }
    setValues({
      ...values,
      [fieldName]: formattedValue,
    });
  };

  const navigate = useNavigate();
  // const navigatePassenger = () => {
  //   navigate("/passengers");
  // };
  const handleSubmit = async (e) => {
    e.preventDefault();
    for (let key of Object.keys(studentData)) {
      if (studentData[key] === "") {
        delete studentData[key];
      }
    }
    await dispatch(addPassengerFormAction(studentData, navigate));
    // navigate("/passengers")
  };
  const [showDropDownFacility, setshowDropDownFacility] = useState(false);

  const openDropDownFacility = () => {
    setshowDropDownFacility((showDropDownFacility) => !showDropDownFacility);
  };

  return (
    <>
      <div className="d-flex flex-column w-tables rounded mt-3 bg-white px-4 pb-3">
        <div
          id="leads-table_wrapper"
          className="dataTables_wrapper dt-bootstrap4 no-footer"
        >
          <form onSubmit={handleSubmit}>
            <div className="row p-3">
              <div className="col-md-6">
                <div className="form-group my-3">
                  <label
                    className="f-14 text-dark-grey mb-12"
                    data-label=""
                    htmlFor="after_days"
                  >
                    First Name
                  </label>
                  <sup className="text-danger f-12">*</sup>
                  <input
                    type="text"
                    className="form-control height-35 f-14"
                    placeholder="Enter Your First Name"
                    autoComplete="false"
                    value={values.firstName}
                    onChange={handleChange("firstName")}
                    required
                    maxLength={35}
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group my-3">
                  <label
                    className="f-14 text-dark-grey mb-12"
                    data-label=""
                    htmlFor="after_days"
                  >
                    Last Name
                  </label>
                  <sup className="text-danger f-12">*</sup>
                  <input
                    type="text"
                    className="form-control height-35 f-14"
                    placeholder="Enter Your Last Name"
                    name="lastName"
                    autoComplete="false"
                    value={values.lastName}
                    onChange={handleChange("lastName")}
                    required
                    maxLength={35}
                  />
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group my-3">
                  <label
                    className="f-14 text-dark-grey mb-12"
                    data-label=""
                    htmlFor="after_days"
                  >
                    Email
                  </label>
                  {/* <sup className="text-danger f-12">*</sup> */}
                  <input
                    type="Email"
                    className="form-control height-35 f-14"
                    placeholder="Enter Your Email"
                    autoComplete="false"
                    value={values.email}
                    onChange={handleChange("email")}
                  />
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group my-3">
                  <label
                    className="f-14 text-dark-grey mb-12"
                    data-label=""
                    htmlFor="after_days"
                  >
                    Phone
                  </label>
                  {/* <input
                    type="tel"
                    className="form-control height-35 f-14"
                    minLength="14"
                    maxLength="14"
                    onKeyDown={(e) => {
                      if (e.ctrlKey && e.code === "KeyV") {
                        return;
                      }
                      if (alphabets.includes(e.key)) {
                        e.preventDefault();
                      }
                      if (e.code === "Backspace" && values.phone.length === 6) {
                        setValues({
                          ...values,
                          phone: values.phone.substring(0, 4),
                        });
                      }
                      if (e.code === "Backspace" && values.phone.length === 2) {
                        setValues({
                          ...values,
                          phone: "",
                        });
                      }
                      if (
                        e.code === "Backspace" &&
                        values.phone.length === 10
                      ) {
                        setValues({
                          ...values,
                          phone: values.phone.substring(0, 9),
                        });
                      }
                    }}
                    name="phone"
                    value={values.phone}
                    onChange={handlePhone("phone")}
                    placeholder="(123)-456-7890"
                  /> */}
                  <input
                    type="tel"
                    className="form-control height-35 f-14"
                    name="phone"
                    value={values.phone}
                    onChange={handlePhone("phone")}
                    placeholder="(123)456-7890"
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group my-3">
                  <div className="d-flex justify-content-between">
                    <div className="d-flex">
                      <div>
                        <label
                          className="f-14 text-dark-grey mb-12"
                          data-label=""
                          htmlFor="after_days"
                        >
                          Facility
                        </label>
                      </div>
                    </div>
                    <div>
                      <button
                        className="bg-button"
                        type="button"
                        id="customerName"
                        data-toggle="modal"
                        data-target="#exampleModalCenter"
                        onClick={() => {
                          openDropDownFacility();
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="currentColor"
                          className="bi bi-plus-circle-fill fa-w-20 f-20 text-dark"
                          viewBox="0 0 16 16"
                        >
                          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                        </svg>
                      </button>
                    </div>
                  </div>
                  {/* <input
                      type="Facility"
                      className="form-control height-35 f-14"
                      placeholder="Enter Your Facility"
                      autoComplete="false"
                      value={values.facility}
                      onChange={handleChange("facility")}
                      required
                    /> */}
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    defaultValue={""}
                    value={facility
                      ?.map((x) => ({
                        label: `${x.name}`,
                        value: x.id,
                      }))
                      .find((e) => e.value === values?.facility)}
                    isDisabled={false}
                    isClearable={false}
                    isRtl={false}
                    isSearchable={true}
                    // required={true}
                    name="facility"
                    id="facility"
                    // onChange={handleChange("facility")}
                    onChange={(e) => {
                      setValues({
                        ...values,
                        facility: e.value,
                      });
                    }}
                    options={facility?.map((x) => ({
                      label: `${x.name}`,
                      value: x.id,
                    }))}
                  />
                </div>
              </div>
              {/* <div className="col-md-6">
                <div className="form-group my-3 ">
                  <div className="d-flex justify-content-between">
                    <div className="d-flex">
                      <label
                        className="f-14 text-dark mb-12"
                        data-label=""
                        htmlFor="Name"
                      >
                        {" "}
                        Select School
                      </label>
                      <sup className="text-danger f-12">*</sup>
                    </div>
                    <div>
                      <button
                        className="bg-button"
                        type="button"
                        data-toggle="modal"
                        data-target="#exampleModalCenter"
                        onClick={() => setShowModal(true)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="currentColor"
                          className="bi bi-plus-circle-fill fa-w-20 f-20 text-dark"
                          viewBox="0 0 16 16"
                        >
                          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <select
                    className="form-control height-35 f-14"
                    name="school"
                    onChange={(e) => setSelectedCompanyId(e.target.value)}
                    value={selectedCompanyId}
                    required
              
                  >
                    <option value="">Select School</option>
                    {externalCompanies.list.map((x) => (
                      <option value={x.id}>{x.name}</option>
                    ))}
                  </select>
                </div>
              </div> */}
              <div className="col-md-6">
                <div className="form-group my-3 ">
                  <div className="">
                    <label
                      className="f-14 text-dark mb-12"
                      data-label=""
                      htmlFor="Name"
                    >
                      {" "}
                      Service Type
                    </label>
                    <sup className="text-danger f-12">*</sup>
                  </div>
                  <select
                    className="form-control height-35 f-14"
                    onChange={handleChange("type")}
                    required
                  >
                    <option value="">Select Type</option>
                    <option value="Individual">Individual</option>
                    <option value="Company">Payer Name/Company</option>
                  </select>
                </div>
              </div>

              {values.type === "Company" && (
                <div className="col-md-6">
                  <div className="form-group my-3 ">
                    <div className="d-flex justify-content-between">
                      <div className="">
                        <label
                          className="f-14 text-dark mb-12"
                          data-label=""
                          htmlFor="Name"
                        >
                          {" "}
                          Payer Name/Company
                        </label>
                        <sup className="text-danger f-12">*</sup>
                      </div>
                      <div>
                        <button
                          className="bg-button"
                          type="button"
                          data-toggle="modal"
                          data-target="#exampleModalCenter"
                          onClick={() => setShowModal(true)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="currentColor"
                            className="bi bi-plus-circle-fill fa-w-20 f-20 text-dark"
                            viewBox="0 0 16 16"
                          >
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                          </svg>
                        </button>
                      </div>
                    </div>
                    <select
                      className="form-control height-35 f-14"
                      id="Name"
                      onChange={(e) => setSelectedCompanyId(e.target.value)}
                      value={selectedCompanyId}
                      required
                    >
                      <option value="">Select Payer Name/Company</option>
                      {externalCompanies.list.map((x) => (
                        <option value={x.id}>{x.name}</option>
                      ))}
                    </select>
                  </div>
                </div>
              )}

              <div className="col-md-12">
                <label htmlFor="allowed_file_types">Note: </label>
                <textarea
                  type="text"
                  name="allowed_file_types"
                  id="allowed_file_types"
                  placeholder="Enter The Note"
                  className="form-control f-14"
                  value={values.notes}
                  onChange={handleChange("notes")}
                  autoComplete="false"
                ></textarea>
              </div>
              <div className="col-lg-12">
                <div
                  className="form-group "
                  style={{ marginTop: "1.5em !important" }}
                >
                  {/* <button type="button" className="btn-primary rounded f-14 p-2 mr-3"
                  onClick={handleSubmit}
                  >
                    Submit
                  </button> */}
                  <br />
                  <button
                    type="submit"
                    className="btn-primary rounded f-14 p-2 mr-3"
                    disabled={loading}
                    // onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <AdminSettingPaymentAddCompanyModal
        {...{ showModal, setShowModal, setSelectedCompanyId }}
      />
      <AdminSettingAddFacilityModal
        showDropDown={showDropDownFacility}
        openDropDown={openDropDownFacility}
        values={values}
        setValues={setValues}
      />
    </>
  );
};
export default AdminPassengerAddComponent;
